<template lang="pug">
.container.text-center.mt-5
  first-heading(:title='title')

  p {{ message }}

  b-spinner
</template>

<script>
import auth from '@/modules/auth'
import events from '@/modules/events'

export default {
  name: 'SessionExpired',

  props: {
    title: {
      type: String,
      default: 'Sessão expirada',
    },

    message: {
      type: String,
      default: 'É necessário fazer login novamente...',
    },
  },

  mounted() {
    this.logout()
  },

  methods: {
    async logout() {
      try {
        await this.$http.post('/oauth/revoke', { token: auth.token })
        auth.expire()
        this.$router.push('/login')
      } catch (error) {
        events.toastMessage('Erro', 'Falha o encerrar a sessão', 'danger')
        this.$router.push('/')
      }
    },
  },
}
</script>
